import ContentModes from 'utils/contentModeUtil';

const TOGGLE_CONTENT = 'TOGGLE_CONTENT';

export const toggleContent = () => ({
    type: TOGGLE_CONTENT,
});

const initialState = {
    isPregnancy: true,
    contentType: ContentModes.PREGNANCY,
};

const toggleContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_CONTENT: {
            const newIsPregnancy = !state.isPregnancy;

            return {
                ...state,
                isPregnancy: newIsPregnancy,
                contentMode: newIsPregnancy ? ContentModes.PREGNANCY : ContentModes.PARENTS,
            };
        }

        case 'SET_INITIAL_CONTENT': {
            return {
                ...state,
                ...action.payload, 
            };
        }

        default:
            return state;
    }
};

export default toggleContentReducer;