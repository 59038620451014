import axiosServices from 'utils/axios';

export const getOne = async (campaignId) => {
    try {
        const response = await axiosServices.get(`admin/campaigns/campaign/${campaignId}`);

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const getCampaignDetails = async (campaignId) => {
    try {
        const response = await axiosServices.get(`admin/campaigns/campaign-details/${campaignId}`);

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const getAll = async (page, limit, order, orderBy) => {
    try {
        const response = await axiosServices.get(`admin/campaigns/`, {
            params: {
                page: page,
                limit,
                order,
                orderBy,
            }
        });

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const getSearched = async (page, limit, search, order, orderBy, country, partner, language, active) => {
    try {
        const response = await axiosServices.get(`admin/campaigns/search/`, {
            params: {
                page: page,
                limit,
                search,
                order,
                orderBy,
                language,
                country,
                partner,
                active
            }
        });

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const getPartners = async () => {
    try {
        const response = await axiosServices.get(`admin/campaigns/partners`);
        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const save = async (campaignData) => {
    try {
        const response = await axiosServices.post(`admin/campaigns/create`, campaignData);

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const edit = async (campaignData) => {
    try {
        const { id, ...data } = campaignData;

        const response = await axiosServices.put(`admin/campaigns/${id}`, data);

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const remove = async (campaignId) => {
    try {
        const response = await axiosServices.delete(`admin/campaigns/${campaignId}`);

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const getNotPublishedCampaigns = async () => {
    try {
        const response = await axiosServices.get(`admin/campaigns/not-published`);

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};

export const enableDisableCampaign = async (isEnabled, campaignId) => {
    try {
        const response = await axiosServices.put(`admin/campaigns/${campaignId}/enableCampaign`, { isEnabled });

        return response.data;
    } catch (error) {
        throw new Error(error.code + " " + error.message);
    }
};