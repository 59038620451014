import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const dateFormat = (dateString) => {
    const date = dayjs(dateString);
    return dateString ? `${date.date()}/${date.month() + 1}/${date.year()}` : "";
};

export const getNumberOfDays = (start, end) => {
    const startDate = dayjs(start);
    const endDate = dayjs(end);

    const diffInDays = endDate.diff(startDate, 'day');

    return diffInDays;
};

export const dateFromUnix = (inputDate) => {
    const date = dayjs.unix(inputDate);

    return inputDate ? `${date.date()}-${date.month() + 1}-${date.year()} ${date.hour()}:${date.minute()}:${date.second()}` : 'Up to date!';
};

export const fullDateWithHours = (dateString) => {
    const date = dayjs(dateString);

    let hours = date.hour();
    if (hours <= 9) {
        hours = "0" + hours;
    }
    
    let minutes = date.minute();
    if (minutes <= 9) {
        minutes = "0" + minutes;
    }

    let seconds = date.second();
    if (seconds <= 9) {
        seconds = "0" + seconds;
    }

    return dateString ? `${date.date()}/${date.month() + 1}/${date.year()}    ${hours}:${minutes}:${seconds}` : "";
};