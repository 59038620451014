import { useEffect, useState, useRef } from 'react';

import { Grid, Box, CircularProgress, Typography, Backdrop } from '@mui/material';

const PublishSpinner = ({ showSpinner, open, close, value, ...props }) => {
    const [progress, setProgress] = useState(0);
    // const [buffer, setBuffer] = useState(10);

    const progressRef = useRef(() => { });

    useEffect(() => {
        progressRef.current = () => {
            if (progress > 100) {
                setProgress(0);
                // setBuffer(10);
            } else {
                const diff = Math.random() * 10;
                // const diff2 = Math.random() * 10;
                setProgress(progress + diff);
                // setBuffer(progress + diff + diff2);
            }
        };
    });

    useEffect(() => {
        const timer = setInterval(() => {
            progressRef.current();
        }, value);

        return () => {
            clearInterval(timer);
        };
    }, [value]);

    return (
        <Backdrop
            sx={{ color: 'red', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            close={close}
            style={{ zIndex: '9999' }}
        >
            <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                    <Typography variant="h1" component="div" color="#FFF"> {showSpinner ? 'Please wait!' : 'Publish... Please wait!'}</Typography>
                </Grid>
                <Grid item>
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'inline-flex'
                        }}
                    >
                        <CircularProgress size={150} {...props} style={{ color: showSpinner && 'rgb(26, 188, 156,0.7)' }} />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Typography variant="subtitle2" component="div" color="#FFF">{`${Math.round(progress)}%`}</Typography>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Backdrop>
    );
};

export default PublishSpinner;