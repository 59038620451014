import { SET_PARTNER_ID } from "actions/actionTypes";

const initialState = {
    partnerId: ''
};


const transferDataReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PARTNER_ID:
            return {
                ...state,
                partnerId: action.payload
            };
        default:
            return state;
    }
};

export default transferDataReducer;