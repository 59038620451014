const ENV = process.env.REACT_APP_ENV ?? "local";

const ENV_COLOR = {
  local: "#00C853",
  test: "#bdb535",
  stage: "#ff8432",
  prod: "#C62828",
};

const Environment = {
  name: ENV,
  color: ENV_COLOR[ENV],
};

export default Environment;
