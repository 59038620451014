import { SET_WARNING_CAMPAIGN_MESSAGE, UNSET_WARNING_CAMPAIGN_MESSAGE } from 'actions/actionTypes';

const initialState = {
    action: false,
    setWarning: false,
    unSetWarning: false
};

const setWarning = (state = initialState, action) => {
    switch (action.type) {
        case SET_WARNING_CAMPAIGN_MESSAGE:
            return {
                ...state,
                setWarning: action.setWarning
            };
        case UNSET_WARNING_CAMPAIGN_MESSAGE:
            return {
                ...state,
                unSetWarning: action.unSetWarning
            }
        default:
            return state;
    }
};

export default setWarning;

export const getWarning = (state) => state;