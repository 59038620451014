import { useDispatch } from 'react-redux';

import {
    showSuccessNotification,
    showErrorNotification,
    showWarningNotification,
    showSearchNotification
} from 'actions/notificationActions';

const useNotification = () => {
    const dispatch = useDispatch();

    const showSuccess = (message) => dispatch(showSuccessNotification(message));
    const showError = (message) => dispatch(showErrorNotification(message));
    const showWarning = (message) => dispatch(showWarningNotification(message));
    const showSearch = (message) => dispatch(showSearchNotification(message))

    return {
        showSuccess,
        showError,
        showWarning,
        showSearch
    };
};

export default useNotification;