import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { toggleContent } from 'reducers/toggleContentReducer';

import pregnant from 'assets/pregnant.png';
import toddler from 'assets/toddler.png';

import { Grid, FormGroup, FormControlLabel, Switch, Typography, Box } from '@mui/material';
import ContentModes from 'utils/contentModeUtil';

const MaterialUISwitch = styled(Switch)(({ checked }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                content: "''",
                position: 'absolute',
                width: '100%',
                height: '100%',
                left: 0,
                top: 0,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundImage: `url(${pregnant})`, // Set toddler image when checked
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: checked ? '#b271b0a4' : '#4a6a9189', // Background color when checked (Parents)
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: checked ? '#B271B0' : '#4A6A91',
        width: 32,
        height: 32,
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url(${toddler})`,  // Set pregnant image when not checked
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: checked ? '#b271b0a4' : '#4a6a9189', // Background color when checked (Parents)
        borderRadius: 20 / 2,
    },
}));

export default function ContentToggle() {
    const dispatch = useDispatch();
    const isPregnancy = useSelector((state) => state.toggleContent.isPregnancy); // Get toggle state from Redux
    const contentMode = useSelector(state => state.toggleContent.contentMode);

    useEffect(() => {
        if (!contentMode) {
            dispatch({
                type: 'SET_INITIAL_CONTENT',
                payload: {
                    isPregnancy: true,
                    contentMode: ContentModes.PREGNANCY,
                },
            });
        }
    }, [contentMode, dispatch]);

    const handleToggle = () => {
        dispatch(toggleContent());
    };

    return (
        <Box ml={3}>
            <Grid container alignItems='center' justifyContent='center'>
                <Typography color={!isPregnancy && '#4A6A91'} mr={3}>Parents</Typography>
                <FormGroup>
                    <FormControlLabel
                        control={<MaterialUISwitch checked={isPregnancy} onChange={handleToggle} />}
                    />
                </FormGroup>
                <Typography color={isPregnancy && '#B271B0'} >Pregnancy</Typography>
            </Grid>
        </Box>
    );
}
