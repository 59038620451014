import React from 'react';
import { modalType } from 'utils/initialModalValues';

import AnimateButton from 'ui-component/extended/AnimateButton';
import { Button, Grid } from '@mui/material';

const ConfirmationButtons = ({ openPopUp, type, handleSubmit, disabled }) => {
    return (
        <Grid style={{ display: 'flex', gap: '2vw' }}>
            {handleSubmit != undefined &&
                <AnimateButton>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#58A665'
                        }}
                        onClick={handleSubmit}
                        disabled={disabled}
                    >
                        {type === modalType.add || type === modalType.duplicate
                            ? 'Create'
                            : 'Save'
                        }
                    </Button>
                </AnimateButton>
            }
            <AnimateButton>
                <Button
                    onClick={openPopUp}
                    variant="contained"
                    style={{
                        backgroundColor: '#F18868'
                    }}
                >
                    Cancel
                </Button>
            </AnimateButton>
        </Grid>
    )
};

export default ConfirmationButtons;