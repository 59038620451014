// material-ui
import { styled } from '@mui/material/styles';
import {
    Divider,
    List,
    ListItemText,
    ListItem,
    Grid,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { dateFormat } from 'utils/dateFunctions';
// assets
import { IconMessageCircle2, IconReport } from '@tabler/icons';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    alignItems: 'flex-start',
    marginLeft: 2,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ notPublishedCampaigns }) => {

    return (
        <List
            sx={{
                width: '25rem',
                maxWidth: 700,
                py: 0,
                borderRadius: '10px',
                '@media (max-width: 960px)': {
                    maxWidth: 500
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notPublishedCampaigns.map(c => (
                <Link
                    key={c.id}
                    to={c.type === 'comment' ? '/users-feedback' : '/campaigns'}
                    onClick={() => {
                        localStorage.setItem('id', `${c.id}`);
                        localStorage.setItem('mode', 'edit');
                    }}
                    target="_blank"
                    rel='noopener noreferrer'
                >
                    <ListItemWrapper>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                {c?.type === 'comment'
                                    ? <IconMessageCircle2 />
                                    : <IconReport />
                                }
                            </Grid>
                            <Grid item xs>
                                <Typography variant="h4" style={{ wordBreak: 'break-word' }}>
                                    {c?.type === 'comment'
                                        ? `New msg in the User's Feedback`
                                        : `Campaign "Video Rewarded Test -Неда" will expire on ${dateFormat(c?.end_date)}`
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        <ListItem alignItems="flex-start" sx={{ ml: 5 }}>
                            <ListItemText primary={c.message} />
                        </ListItem>
                        <Grid container direction="column" className="list-container" alignItems="flex-start">
                            <Grid item xs={12} sx={{ mt: 2, mb: 2, textAlign: 'left' }}>
                                <Typography variant="body">
                                    {dateFormat(c?.date)} created {c?.type === 'comment' ? 'comment' : 'campaign'}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                    </ListItemWrapper>
                </Link>
            ))}
        </List>
    );
};

export default NotificationList;
