import axios from "axios";
import firebase from "config/firebase";
import dayjs from "dayjs";

export const ENV_URL = {
  test: "https://test.feia-apps.com/api/",
  stage: "https://stage.feia-apps.com/api/",
  prod: "https://live.feia-apps.com/api/",
};

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? ENV_URL[process.env.REACT_APP_ENV]
    : "http://localhost:3000/api/";

const axiosServices = axios.create({
  baseURL: BASE_URL
  //  baseURL: ENV_URL.test
});

axiosServices.interceptors.request.use(async (config) => {
  const expirationTime = sessionStorage.getItem("expirationTime");

  const isExpired = dayjs(expirationTime).unix() - dayjs().unix() < 10;

  if (isExpired) {
    const newToken = await firebase.auth().currentUser.getIdTokenResult();

    sessionStorage.setItem("token", newToken.token);
    sessionStorage.setItem("expirationTime", newToken.expirationTime);
  }

  const result = sessionStorage.getItem("token");
  config.headers.Authorization = result ? `Bearer ${result}` : "";

  return config;
});

axiosServices.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(
      (error.response && error.response.data) || "Wrong Services"
    );
  }
);

export default axiosServices;
