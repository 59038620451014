import { forwardRef } from 'react';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    Grid,
    Typography,
} from '@mui/material';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const AlertModal = ({ ...props }) => {
    return (
        <Dialog
            style={{ zIndex: '99999' }}
            open={
                props.closePopUp ? props?.openEdit : props.open
            }
            TransitionComponent={Transition}
            keepMounted
            onClose={props.closeModal}
            aria-labelledby="alert-dialog-slide-title1"
            aria-describedby="alert-dialog-slide-description1"
        >
            <DialogTitle id="alert-dialog-slide-title1">
                {
                    props.stopCampaign
                        ? props.tableName
                        : props.closePopUp
                            ? props.message?.title || `Are you sure you want to close this ${props.tableName} before save?`
                            : `Are you sure you want to delete this ${props.tableName}`
                }

            </DialogTitle>
            {
                props.message?.text
                && <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>{props.message.text}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
            }

            <DialogActions sx={{ pr: 2.5 }}>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: '#F18868',
                        width: '6vw'
                    }}

                    onClick={() => {
                        props.closePopUp
                            ? props.setClosePopUp(false)
                            : props.closeModal()
                    }}

                >
                    {
                        props.closePopUp
                            ? 'No'
                            : 'Disagree'
                    }
                </Button>
                <Button
                    variant="contained"
                    style={{
                        backgroundColor: '#58A665',
                        width: '6vw'
                    }}
                    onClick={() => {
                        props.closePopUp
                            ? props.closeModals()
                            : props.onDeleteHandler()
                    }}>
                    {
                        props.closePopUp
                            ? 'Yes'
                            : 'Agree'
                    }
                </Button>
            </DialogActions>
        </Dialog>
    )
};

export default AlertModal;